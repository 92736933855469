
import { defineComponent } from 'vue';
import {Button, Searchbar} from "@/ui";

export default defineComponent({
    name: "WorkingTimeSchemeList",
    components: { Button, Searchbar },
    methods:{
        handleShowCreateForm(){
            this.$router.push('/administration/workingtimescheme/form/0')
        }
    }
})
